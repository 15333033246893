
.ubiq-th {
    @apply
        px-6
        py-3
        border-b
        border-gray-200
        bg-gray-50
        text-xs
        font-medium
        text-gray-500
        uppercase
        tracking-wider
}

.ubiq-tbody {
    @apply
        bg-white
        divide-y
        divide-gray-100
}

.ubiq-td {
    @apply
        px-6
        py-3
        text-sm
        font-medium
}


