.btn {
    @apply
        inline-flex
        justify-center
        items-center
        px-4
        py-2
        font-semibold
        text-xs
        rounded-md
        uppercase
        tracking-widest
        transition
        ease-in-out
        duration-150
}

.btn:focus {
    @apply
        outline-none

        ring
        ring-offset-2
        ring-teal-300
}

.btn:disabled {
    @apply
        opacity-25
        cursor-not-allowed
}
.primary-btn {
    @apply
        bg-teal-500
        border
        border-teal-500
        text-white
}

.primary-btn:hover {
    @apply
        bg-teal-700
        border
        border-teal-700
        text-white
}

.primary-btn:active {
    @apply
        bg-teal-700
}
.primary-btn:focus {
    @apply
        border-teal-700
}


.secondary-btn {
    @apply
        bg-white
        border
        border-gray-300
        text-gray-700
}

.secondary-btn:hover {
    @apply
        bg-gray-50
}


.warning-btn {
    @apply
        bg-orange-500
        border
        border-orange-500
        text-white
}

.warning-btn:hover {
    @apply
        bg-orange-700
        border
        border-orange-700
        text-white
}

.warning-btn:active {
    @apply
        border-orange-700
}

.warning-btn:focus {
    @apply
        bg-orange-700
}

.info-btn {
    @apply
    bg-blue-500
    border
    border-blue-500
    text-white
}

.info-btn:hover {
    @apply
    bg-blue-700
    border
    border-blue-700
    text-white
}

.info-btn:active {
    @apply
        border-blue-700
}

.info-btn:focus {
    @apply
        bg-blue-900
}



.danger-btn {
    @apply
    bg-red-500
    border
    border-red-500
    text-white
}

.danger-btn:hover {
    @apply
    bg-red-700
    border
    border-red-700
    text-white
}

.danger-btn:active {
    @apply
    bg-red-900
}

.button-icon-link {
    @apply
        h-4
        w-4
        -ml-1
        inline-flex
        items-center
}

.icon-link {
    @apply
        h-4
        w-4
        mr-3
        text-gray-400
        fill-current
}

.icon-link-text {
    @apply
        flex
        items-center
        align-middle
        px-4
        py-2
        text-sm
        text-gray-700
}
.icon-link-text:hover {
    @apply
        bg-gray-100
        text-gray-900
}

.context-btn {
    @apply
        w-full
}

.context-btn:focus {
    @apply
        outline-none
}

.link-btn {
    @apply
        mt-1
        text-xs
        text-teal-600
}

.link-btn:hover {
    @apply
        text-teal-500
}

.link-btn:active, .link-btn:focus{
    @apply outline-none
}


