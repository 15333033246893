

@import 'tailwindcss/base';

@import "../fontawesome-pro-5.13.1/css/fontawesome.css";
@import "../fontawesome-pro-5.13.1/css/regular.css";


@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '../../vendor/spatie/laravel-medialibrary-pro/resources/js/media-library-pro-styles/src/styles.css';

@import "./components/dropdown.css";
@import "./components/sidebar.css";
@import "./components/button.css";
@import "./components/table.css";
@import "./components/form.css";

.logo {
    height: 60px;
    width: 60px;
}

input.multiselect__input {
    --tw-ring-color: transparent;
}
input.multiselect__input:focus {
    --tw-ring-color: transparent;
}

.multiselect__tag,
.multiselect__option--highlight,
.multiselect__option--highlight:after{
    background:#14B8A6 !important;
}


.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}

.fade-enter, .fade-leave-active {
    opacity: 0
}

input[type=tel], input[type=text]:focus {

}

.media-library-listerrors {
    border-width: 0;
}

.media-library-input {
    @apply flex-1;
    @apply w-full;
    @apply text-xs;
    @apply text-gray-800;
    @apply rounded-sm;

    @apply px-2;
    @apply py-1;

}

.media-library-input:focus {
    @apply
        outline-none
        shadow-none
        ring-offset-0
        ring-transparent
        ring-offset-transparent;
}


