.sidebar-icon {
    @apply
        mr-3
        h-5
        w-5
        fill-current
        text-gray-400
}
.sidebar-icon-active {
    @apply
        mr-3
        h-5
        w-5
        fill-current
        text-gray-500
}
.sidebar-item {
    @apply
        flex
        text-gray-600
        items-center
        px-2
        py-2
        text-base
        leading-5
        font-medium
        rounded-md
}
.sidebar-item:hover {
    @apply
        text-gray-900
        bg-gray-50
}
.sidebar-item-active {
    @apply
        flex
        items-center
        px-2
        py-2
        text-base
        leading-5
        font-medium
        rounded-md
        text-gray-900
        bg-gray-200
}
