.dropdown-menu-link {
    @apply
        block
        px-4
        py-2
        text-sm
        text-gray-700
}

.dropdown-menu-link:hover {
    @apply
        bg-gray-100
        text-gray-900
}
