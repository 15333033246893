.ubiq-input-label {
    @apply
    block font-medium text-base text-gray-700
}
.error-message {
    @apply
    mt-2 text-sm text-red-600
}
.success-message {
    @apply
    mt-2 text-sm text-green-600
}


